import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from './App'
import './index.css'

// ReactDOM.render is no longer used in React 18 so I've removed it
// ReactDOM.render(<App />, document.getElementById('root'));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement)

root.render(
    <StrictMode>
        <App />
    </StrictMode>    
)